<template>
  <div>
    <Title :pages="[{ icon: 'receipt', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <ModalRemito
        v-if="create_access"
        :remito="mainItem"
        :formBtn="formBtn"
        :dialog="dialog"
        @closeDialog="dialog = false"
        @reload="filter"
        @updateItem="
          (newItem) => {
            remito = newItem;
          }
        "
      />

      <ModalAsignacionFactura :remito="remitoAsignacionFactura" :dialog="dialogAsignacionFactura" @closeDialog="dialogAsignacionFactura = false" @reload="filter" />

      <DialogGenerarComprobante
        title="Generar Comprobante"
        :open="open_generate_comprobante"
        @closeDialog="closeComprobante"
        @reload="filter"
        :receiver="voucheReceiver"
        :emitter="voucheEmitter"
        :items="items_remitos"
        :remitos="selected"
        :shipping_address="shipping_address_selected"
      />

      <v-btn color="primary" class="mb-5" dark @click="dialog = true">Crear Remito</v-btn>

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="emitter_selected"
                    autocomplete="off"
                    data-vv-as="emitter_selected"
                    name="emitter_selected"
                    item-text="name"
                    item-value="id"
                    :items="emitters"
                    label="Emisor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="receiver_selected"
                    autocomplete="off"
                    data-vv-as="receiver_selected"
                    name="receiver_selected"
                    item-text="name"
                    item-value="id"
                    :items="receivers"
                    label="Receptor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="state_selected"
                    autocomplete="off"
                    data-vv-as="state_selected"
                    name="state_selected"
                    item-text="name"
                    item-value="id"
                    :items="states"
                    label="Estado"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field clearable v-model="voucher_number_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Factura"> </v-text-field>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field clearable v-model="remito_number_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Remito"> </v-text-field>
                </div>

                <div class="col-md-3 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <div v-if="selected.length != 0" class="col-md-12" style="background: #f4f1f1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="green" class="cursor mb-1 mt-1" @click="generateInvoiceModal">post_add</v-icon>
            </template>
            <span>Generar facturas</span>
          </v-tooltip>
        </div>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
          v-model="selected"
          show-select
          :single-select="false"
        >
          <template v-slot:item="{ item, isSelected, select }">
            <tr>
              <td>
                <v-checkbox :input-value="isSelected" style="margin: 0px; padding: 0px" hide-details @change="select($event)" :disabled="item.state.id == remitosWS.STATE_BILLED" />
              </td>
              <td>{{ item.cbte_fch }}</td>
              <td>{{ item.remito_label }}</td>
              <td>{{ item.emitter.name }}</td>
              <td>{{ item.receiver.name }}</td>

              <td>{{ item.voucher != null ? item.voucher.voucher_number_label : "-" }}</td>
              <!--<td>{{ item.state.name }}</td>-->
              <td class="text-center px-0">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" small color="green" class="mr-2"> print </v-icon>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="cursor" @click="printSyscore(item)">Imprimir remito</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="cursor" @click="printCustom(item)">Imprimir remito preimpreso</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewItem(item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.state.id == 0">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="violet" class="mr-2" @click="assignVoucher(item)"> post_add </v-icon>
                  </template>
                  <span v-if="item.voucher != null">Reasignar factura</span>
                  <span v-else>Asignar factura</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.state.id == 0">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
      <DialogLoading :dialog="dialog_loading" text="Cargando..." />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { emmiterService } from "@/libs/ws/emmiterService";
import { receiversService } from "@/libs/ws/receiversService";
import { generalService } from "@/libs/ws/generalService";
import { remitoService } from "@/libs/ws/remitoService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import ModalRemito from "@/views/Remitos/ModalRemito.vue";
import DialogGenerarComprobante from "@/views/Billing/Vouchers/DialogGenerarComprobante.vue";
import ModalAsignacionFactura from "@/views/Remitos/ModalAsignacionFactura.vue";
import DialogLoading from "@/components/DialogLoading.vue";
export default {
  components: { Title, DialogDelete, ModalRemito, DialogGenerarComprobante, ModalAsignacionFactura, DialogLoading },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      items_remitos: [],
      shipping_address_selected: null,
      remitoAsignacionFactura: null,
      dialogAsignacionFactura: false,
      voucheReceiver: null,
      voucheEmitter: null,
      open_generate_comprobante: false,
      selected: [],
      remitosWS: remitoService,
      formatUtils: formatUtils,
      parent: this,
      service: remitoService,
      page_title: "Remitos",
      modal_title: "Remito",
      mainItem: {
        id: 0,
        emitter: null,
        receiver: null,
        voucher: null,
        observation: null,
        shipping_address: null,
        pto_vta: null,
        num_remito: null,
        items: [],
        cbte_fch: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
      },
      dialog_delete: false,
      title_dialog: "",
      message_dialog: "",
      item_dialog: null,
      defaultItem: null,
      headers: [
        { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
        { text: "Remito", align: "left", sortable: true, value: "remito_label" },
        { text: "Emisor", align: "left", sortable: true, value: "emitter.name" },
        { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
        { text: "Factura", align: "left", sortable: true, value: "voucher.voucher_number_label" },
        /*{ text: "Estado", align: "left", sortable: true, value: "state.name" }, */
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      receivers: [],
      emitters: [],
      states: [],
      desde_selected_open: false,
      hasta_selected_open: false,
      receiver_selected: null,
      emitter_selected: null,
      state_selected: null,
      voucher_number_selected: null,
      remito_number_selected: null,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      presentations: [],
      dialog_loading: false,
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service, this.getFilterParams());
  },
  methods: {
    assignVoucher(item) {
      this.remitoAsignacionFactura = item;
      this.dialogAsignacionFactura = true;
    },
    closeDialog() {
      this.item_dialog = null;
      this.title_dialog = "";
      this.message_dialog = "";
    },
    getFilterParams() {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        receiver: this.receiver_selected != null ? this.receiver_selected.id : null,
        emitter: this.emitter_selected != null ? this.emitter_selected.id : null,
        state: this.state_selected != null ? this.state_selected.id : null,
        voucher_number: this.voucher_number_selected,
        remito_number: this.remito_number_selected,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadComboData() {
      emmiterService.filter({ enabled: true }).then((response) => {
        this.emitters = response.result;
      });

      receiversService.filter({}).then((response) => {
        this.receivers = response.result;
      });

      this.service
        .get_states()
        .then((response) => {
          this.states = response.result;
        })
        .catch((e) => {
          console.log("states.filter error");
          console.log(e);
        });
    },
    deleteItemConfirm() {
      this.loading = true;
      this.service
        .remove({ id: this.deleteId })
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.loadList(this.service, this.getFilterParams());
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("spentService.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        })
        .finally(() => {
          this.loading = false;
        });
      this.dialog_delete = false;
    },
    printCustom(item) {
      this.loading = true;
      remitoService
        .print_custom(item.id)
        .then((response) => {
          let nombre = item.remito_label;
          if (!generalService.downloadPdf(response, item.receiver.name + "_" + nombre + ".pdf")) {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.$toast.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    printSyscore(item) {
      this.loading = true;
      remitoService
        .print_syscore(item.id)
        .then((response) => {
          let nombre = item.remito_label;
          if (!generalService.downloadPdf(response, item.receiver.name + "_" + nombre + ".pdf")) {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.$toast.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async generateInvoiceModal() {
      try {
        //Control de receptor
        let receiversIds = this.selected.map((a) => a.receiver.id);
        let uniqueReceiversIds = [...new Set(receiversIds)];
        if (uniqueReceiversIds.length != 1) {
          this.$toast.error("No se puede generar facturas para distintos receptores", "Error");
          return;
        }

        //Control de emisor
        let emittersIds = this.selected.map((a) => a.emitter.id);
        let uniqueEmittersIds = [...new Set(emittersIds)];
        if (uniqueEmittersIds.length != 1) {
          this.$toast.error("No se puede generar facturas para distintos emisores", "Error");
          return;
        }

        this.dialog_loading = true;
        this.presentations = await this.loadProductos(this.selected[0].receiver.client.id);

        this.items_remitos = [];
        this.shipping_address_selected = this.selected[0].shipping_address;

        let newItemsRemitos = [];

        this.selected.map((remito) => {
          remito.items.map((item) => {
            let presentation = this.presentations.find((p) => p.id == item.product_presentation.id);

            let newItem = {
              ...item,
              price: presentation ? presentation.price : 0,
              discount_per: presentation ? presentation.discount_per : 0,
              iva: presentation ? presentation.default_iva_id : null,
            };
            // Agregar a una lista temporal en lugar de modificar directamente items_remitos
            newItemsRemitos.push(JSON.parse(JSON.stringify(newItem)));
          });
        });
        this.items_remitos = newItemsRemitos;

        this.voucheReceiver = this.selected[0].receiver;
        this.voucheEmitter = this.selected[0].emitter;
        this.dialog_loading = false;
        this.open_generate_comprobante = true;
      } catch (e) {
        this.dialog_loading = false;
        console.error("Error al generar factura:", e);
      }
    },
    async loadProductos(client_id) {
      try {
        generalService.setSection(Constant.SECTION_PRODUCTS);
        let response = await generalService.query({ client_id: client_id, sellable: true }, Constant.SECTION_PRODUCTS + "/presentations");
        return response.result;
      } catch (e) {
        console.error("Error al cargar productos:", e);
        return [];
      }
    },
    closeComprobante() {
      this.items_remitos = null;
      this.voucheReceiver = null;
      this.voucheEmitter = null;
      this.shipping_address_selected = null;
      this.open_generate_comprobante = false;
    },
  },
};
</script>
