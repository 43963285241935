<template>
  <div>
    <Title :pages="[{ icon: 'money-check', page: page_title.toUpperCase() }]"></Title>
    <v-container fluid>
      <v-btn color="primary" class="mb-5" dark @click="updateDialog(true)">Crear {{ modal_title }}</v-btn>

      <v-btn class="ml-3 mb-5 text-white" color="blue-grey" @click="dialog_bulk = true">Importar Cheques</v-btn>

      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <ModalBulk
        v-if="create_access"
        modal_title="Importar Cheques"
        :formBtn="formBtn"
        :service="service"
        :dialog="dialog_bulk"
        :mainItem="mainItem"
        @open="dialog_bulk = true"
        @close="dialog_bulk = false"
        @filter="loadList(service)"
      />

      <ModalChangeState :show="dialog_change_state" :modal_title="title_change_state" @refresh="filter" @close="dialog_change_state = false" :action="change_state_action" :cheques="selected" />

      <ModalHistory @updateDialog="updateDialog" @loadList="loadList(service)" :show="dialog_history" @refresh="filter" @close="dialog_history = false" :check="check_history" />

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" @reload="filter" />

      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="check_number_select != null"
                        v-model="desde_selected"
                        label="Desde"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="pt-0 mt-0"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="check_number_select != null"
                        v-model="hasta_selected"
                        label="Hasta"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="pt-0 mt-0"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" v-model="state_selected" item-text="name" item-value="id" :items="states" label="Estado" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field clearable v-model="check_number_select" autocomplete="off" class="pt-0 mt-0" label="Nº Cheque"></v-text-field>
                </div>

                <!--<div class="col-md-3 pt-0">
                  <v-combobox class="pt-0" v-model="fecha_tipo_selected" item-text="name" item-value="id" :items="fechas_tipo" label="Fecha de busqueda"></v-combobox>
                </div>-->

                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0" v-model="view_selected" item-text="name" item-value="id" :items="views" label="Mostrar" clearable></v-combobox>
                </div>
                <div class="col-md-9 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="blue-grey" class="mr-2" dark :loading="dialog_export" @click="exportFile()"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>

                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <h5 v-if="list_access" style="padding: 10px; margin-top: 0px; background: #222d32; color: #fff; font-weight: bold">
        <v-chip color="green" outlined>Total recibido {{ formatUtils.formatPrice(total_a_cobrar) }} </v-chip>
        <v-chip class="ml-3" color="white" outlined>Total emitido {{ formatUtils.formatPrice(total_a_pagar) }} </v-chip>
      </h5>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>

        <div class="mb-4" v-show="selected.length != 0 && update_access">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="border: 1px solid; border-radius: 100px; padding: 3px"
                color="blue-grey"
                :class="isActiveBlinking ? 'ml-3 button_blinking' : 'ml-3'"
                @click="toggleClass"
                v-bind="attrs"
                v-on="on"
              >
                more_vert
              </v-icon>
            </template>
            <v-list>
              <v-list-item dense @click="dialogChangeState('acreditar')">
                <v-list-item-title> Acreditar cheque</v-list-item-title>
              </v-list-item>
              <v-list-item dense @click="dialogChangeState('negotiate')">
                <v-list-item-title> Negociar cheque</v-list-item-title>
              </v-list-item>
              <v-list-item dense @click="dialogChangeState('cobrar_por_ventanilla')">
                <v-list-item-title> Cobrar cheque por ventanilla</v-list-item-title>
              </v-list-item>
              <v-list-item dense @click="dialogChangeState('debitar')">
                <v-list-item-title> Debitar cheque</v-list-item-title>
              </v-list-item>
              <v-list-item dense @click="dialogChangeState('depositar')">
                <v-list-item-title> Depositar cheque</v-list-item-title>
              </v-list-item>
              <v-list-item dense @click="dialogChangeState('rechazar')">
                <v-list-item-title> Rechazar cheque</v-list-item-title>
              </v-list-item>
              <v-list-item dense @click="dialogChangeState('anular')">
                <v-list-item-title> Anular cheque</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="{ item, isSelected, select }">
            <tr>
              <td>
                <v-checkbox :input-value="isSelected" style="margin: 0px; padding: 0px" hide-details @change="select($event)" />
              </td>
              <td>{{ item.payment_date_str }}</td>
              <td>{{ item.expiratino_date_str }}</td>

              <td>{{ item.is_own ? item.emitter.name : item.emitter_name }}</td>
              <td>{{ item.is_own ? item.money_account.name : item.bank.name }}</td>
              <td>{{ item.number }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(item.amount) }}</td>
              <td class="text-center">
                <span :class="getClassState(item.state)">{{ item.state }}</span>
              </td>
              <td>{{ item.observations }}</td>
              <td>{{ item.charge_payment_method }}</td>
              <td>{{ item.spent_payment_method }}</td>
              <td class="text-left px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="grey" class="mr-2" @click="openHistory(item)"> timer </v-icon>
                  </template>
                  <span>Historial</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.state == ChequesState.STATE_EN_CARTERA">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.state == ChequesState.STATE_EN_CARTERA">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import ChequesState from "@/constants/chequesStates";
import { checkService } from "@/libs/ws/checkService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";

import Modal from "./Modal.vue";
import ModalChangeState from "./ModalChangeState.vue";
import ModalHistory from "./ModalHistory.vue";
import ModalBulk from "./ModalBulk.vue";
export default {
  components: { Title, Modal, ModalChangeState, ModalHistory, DialogDelete, ModalBulk },
  mixins: [GenericMixin, RolMixin, ExportMixin],
  data() {
    return {
      dialog_change_state: false,
      dialog_bulk: false,
      isActiveBlinking: true,
      title_change_state: "",
      change_state_action: "",
      ChequesState: ChequesState,
      formatUtils: formatUtils,
      parent: this,
      service: checkService,
      page_title: "Cheques",
      modal_title: "Cheque",
      headers: [
        { text: "", align: "center", sortable: false, value: "" },
        {
          text: "Fecha de Cobro/Pago",
          align: "left",
          sortable: true,
          value: "payment_date",
        },
        {
          text: "Fecha de Vencimiento",
          align: "left",
          sortable: true,
          value: "expiratino_date",
        },

        {
          text: "Emisor",
          align: "left",
          sortable: true,
          value: "emitter.name",
        },
        {
          text: "Banco/Cuenta Monetaria",
          align: "left",
          sortable: true,
          value: "money_account.name",
        },
        {
          text: "Nro Cheque",
          align: "left",
          sortable: true,
          value: "number",
        },
        {
          text: "Importe",
          align: "right",
          sortable: true,
          value: "amount",
        },
        {
          text: "Estado",
          align: "center",
          sortable: true,
          value: "",
        },
        {
          text: "Observacion",
          align: "center",
          sortable: true,
          value: "observations",
        },
        {
          text: "Recibido",
          align: "center",
          sortable: true,
          value: "",
        },
        {
          text: "Utilizado",
          align: "center",
          sortable: true,
          value: "",
        },
        {
          text: "Acciones",
          align: "center",
        },
      ],
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      desde_selected_open: false,
      hasta_selected_open: false,
      list: [],
      dialog_delete: false,
      total_a_pagar: 0,
      total_a_cobrar: 0,
      dialog_history: false,
      check_history: null,
      mainItem: {
        id: "",

        //NO propio
        emitter_name: "",

        //SI propio
        emitter: null,
        money_account: null,

        number: "",
        amount: "",
        emission_date: "",
        bank: null,
        payment_date: "",

        observations: null,
        state: null,

        is_own: false,
      },
      defaultItem: null,
      fechas_tipo: [
        { id: "payment_date", name: "Fecha de Cobro/Pago" },
        { id: "emission_date", name: "Fecha de Emisión" },
      ],
      fecha_tipo_selected: { id: "payment_date", name: "Fecha de Cobro/Pago" },
      view_selected: { id: null, name: "Propios y de terceros" },
      views: [
        { id: null, name: "Propios y de terceros" },
        { id: true, name: "Propios" },
        { id: false, name: "Terceros" },
      ],
      check_number_select: null,
      state_selected: null,
      states: [],
    };
  },
  mounted() {
    this.loadList(this.service, this.getFilterParams());
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.getStates();
  },
  methods: {
    toggleClass() {
      this.isActiveBlinking = false;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .filter(param)
        .then((response) => {
          this.list = response.result;
          this.selected = [];
          this.loading = false;
          this.getTotal();
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    getFilterParams() {
      const param = {
        date_type: this.fecha_tipo_selected != null ? this.fecha_tipo_selected.id : null,
        date_from: this.desde_selected + " 00:00:00",
        date_to: this.hasta_selected + " 23:59:59",
        state: this.state_selected ? this.state_selected.id : null,
        check_number: this.check_number_select,
        is_own: this.view_selected != null ? this.view_selected.id : null,
      };
      return param;
    },
    openHistory(item) {
      this.check_history = item;
      this.dialog_history = true;
    },
    getClassState(state) {
      return checkService.getClassState(state);
    },
    dialogChangeState(action) {
      this.dialog_change_state = true;
      this.change_state_action = action;
      switch (action) {
        case "negotiate":
          this.title_change_state = "Negociar cheque";
          return;
        case "anular":
          this.title_change_state = "Anular cheque";
          return;
        case "acreditar":
          this.title_change_state = "Acreditar cheque";
          return;
        case "cobrar_por_ventanilla":
          this.title_change_state = "Cobrar cheque por ventanilla";
          return;
        case "debitar":
          this.title_change_state = "Debitar cheque";
          return;
        case "depositar":
          this.title_change_state = "Depositar cheque";
          return;
        case "rechazar":
          this.title_change_state = "Rechazar cheque";
          return;
        default:
          return "";
      }
    },
    getStates() {
      this.service
        .getStates()
        .then((response) => {
          this.states = response.result;
        })
        .catch((e) => {
          console.log("service.getStates error");
          console.log(e);
        });
    },
    getTotal() {
      this.total_a_pagar = 0;
      this.total_a_cobrar = 0;
      if (this.list.length > 0) {
        this.list.forEach((element) => {
          if (element.is_own) {
            this.total_a_pagar = (parseFloat(this.total_a_pagar) + parseFloat(element.amount)).toFixed(2);
          } else {
            this.total_a_cobrar = (parseFloat(this.total_a_cobrar) + parseFloat(element.amount)).toFixed(2);
          }
        });
      }
    },
    exportFile() {
      var param = this.getFilterParams();
      param.export = true;
      this.exportExcel(Constant.SECTION_CHECKS + "/list", param, "Cheques.xlsx");
    },
    deleteItemConfirm() {
      this.loading = true;
      this.service
        .remove({ id: this.deleteId })
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.loadList(this.service, this.getFilterParams());
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        })
        .finally(() => {
          this.loading = false;
        });
      this.dialog_delete = false;
    },
  },
};
</script>
