<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="close" max-width="1200px">
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-form v-on:submit.prevent="createItem">
          <v-card-title class="mb-4">
            <span class="headline">Remito</span>
          </v-card-title>
          <v-card-text>
            <div class="row">
              <div class="col-md-12">
                <!--Datos Generales-->
                <DatosGenerales :item="item" :isViewModal="isViewModal()" :open="dialog" :validator="$validator" />

                <!-- Compra con o sin stock -->
                <ConStock :invoice="item" :validator="$validator" :open="dialog" :isViewModal="isViewModal()" />

                <!-- Items Voucher -->
                <Items :remito="item" :open="dialog" :validator="$validator" :isViewModal="isViewModal()" />

                <!--Info Extra-->
                <Shipping :item="item" :client="item.receiver != null ? item.receiver.client : null" :isViewModal="isViewModal()" />
                <!--Totales-->
                <Observation :item="item" :isViewModal="isViewModal()" />
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close()">Cerrar</v-btn>
            <v-btn color="success" v-if="!isViewModal()" text type="submit" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import { remitoService } from "@/libs/ws/remitoService";

import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";

import DatosGenerales from "@/views/Remitos/RemitosComponent/DatosGenerales.vue";
import Items from "@/views/Remitos/RemitosComponent/Items.vue";
import Shipping from "@/components/Comprobantes/Shipping.vue";
import Observation from "@/components/Comprobantes/Observation.vue";
import ConStock from "@/components/Comprobantes/ConStock.vue";

export default {
  mixins: [ModalGenericMixin],
  components: { DatosGenerales, Items, Shipping, Observation, ConStock },
  props: {
    remito: { type: Object, default: null },
    dialog: Boolean,
    button_name: String,
    formBtn: String,
    closeDialog: Function,
    voucher: { type: Object, default: null },
  },
  data() {
    return {
      show: false,
      formatUtils: formatUtils,
      total: 0,
      defaultItem: null,
      item: {
        id: 0,
        warehouse: null,
        client: null,
        observation: null,
        shipping_address: null,
        legal_id: null,
        items: [],
        cbte_fch: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
      },
    };
  },
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  watch: {
    dialog(val) {
      this.$validator.reset();
      this.errors.clear();
    },
    remito: {
      handler: function (val) {
        this.init();
      },
      deep: true,
    },
    voucher: {
      handler: function (newValue) {
        this.initializeFromVoucher();
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
    this.init();
  },
  methods: {
    initializeFromVoucher() {
      if (this.voucher != null) {
        //Initialize from voucher
        this.item = {
          id: 0,
          emitter: this.voucher.emitter,
          receiver: this.voucher.receiver,
          voucher: this.voucher,
          observation: this.voucher.observation,
          shipping_address: this.voucher.shipping_address,
          pto_vta: null,
          num_remito: null,
          items: this.voucher.items_custom,
          cbte_fch: new Date(
            new Date().toLocaleDateString("en-US", {
              timeZone: "America/Buenos_Aires",
            })
          )
            .toISOString()
            .slice(0, 10),
        };

        //Remove ItemVoucher fields
        this.item.items.forEach((item) => {
          delete item.id;
          delete item.imp_iva;
          delete item.iva;
          delete item.price;
          delete item.total;
          delete item.voucher;
        });
      }
    },
    close() {
      this.item = JSON.parse(JSON.stringify(this.defaultItem));
      this.$emit("closeDialog");
    },
    init() {
      if (this.remito != null) {
        this.item = this.remito;
      } else {
        this.initializeFromVoucher();
      }
    },
    customValidations() {
      /*
      if (this.total > this.item.spent.pending_to_paid) {
        this.$toast.error("El importe total del pago no puede ser mayor que el del gasto.", "Error");
        return false;
      }
      */
      return true;
    },
    provide() {
      return {
        $validator: this.$validator,
      };
    },
    createItem(e) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          e.preventDefault();

          if (!this.customValidations()) {
            return;
          }

          this.loading = true;

          let data = JSON.parse(JSON.stringify(this.item));
          data.items_arr = data.items;
          delete data.items; //Para que no sobreescriba

          if (this.item.id > 0) {
            remitoService
              .update(data)
              .then((response) => {
                if (response.status) {
                  this.$toast.success("El remito se modifico correctamente", "OK");
                  this.close();
                  this.$emit("reload");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            remitoService
              .create(data)
              .then((response) => {
                if (response.status) {
                  this.$toast.success("El remito se creo correctamente", "OK");
                  this.close();
                  this.$emit("reload");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((e) => {
                console.log(e);
                //this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
    isViewModal() {
      return this.formBtn == "";
    },
  },
};
</script>
