<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <div class="content-main">
        <v-tabs v-model="tab">
          <v-tab v-for="item in items" :key="item.key">
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="0">
            <CompraPorPeriodo></CompraPorPeriodo>
          </v-tab-item>
          <v-tab-item key="1">
            <CompraPorAnio></CompraPorAnio>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
import CompraPorPeriodo from "./CompraPorPeriodo.vue";
import Title from "@/components/Title.vue";
import CompraPorAnio from "./CompraPorAnio.vue";

export default {
  mixins: [],
  components: { Title,  CompraPorPeriodo, CompraPorAnio},
  data() {
    return {
      page_title: "Reporte de Compras",
      mainItem: {
        id: 0,
        name: "",
      },
      defaultItem: {
        id: 0,
        name: "",
      },
      tab: 0,
      list: [],
      items: [
        { tab: "Por Periodo", key: 0 },
        { tab: "Por Año", key: 1 }
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
