<template>
  <div>
    <v-dialog v-model="show" max-width="1000" @click:outside="close">
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-card-title class="headline mb-4">{{ title }} - {{ voucher_number_label }}</v-card-title>

        <v-card-text>
          <div class="text-right">

            <v-chip outlined color="primary" class="mb-4"
                >Saldo pendiente:&nbsp;<span>{{ formatUtils.formatPrice(pending_to_charged) }}</span></v-chip>
          </div>
          <div class="row">
            <div class="col-md-12">
              <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando...">
                <template v-slot:item="props">
                  <tr>
                    <td>{{ props.item.fecha }}</td>
                    <td>{{ props.item.tipo}}</td>
                    <td>{{ props.item.nro_comprobante}}</td>
                    <td>{{ formatUtils.formatPrice(props.item.total_imputado) }}</td>
                  </tr>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning">
                    {{ "Busqueda sin resultados - " + search }}
                  </v-alert>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="close()"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import { vouchersService } from "@/libs/ws/vouchersService";
import { formatUtils } from "@/libs/utils/formatUtils";

export default {
  components: { },
  mixins: [SettingsMixin],
  props: {
    title: String,
    show: Boolean,
    voucher_id: Number,
    pending_to_charged: Number,
    voucher_number_label: String
  },
  data: () => ({
    list: [],
    formatUtils: formatUtils,
    defaultItem: null,
    loading: false,
    search: "",
    headers: [
      { text: "Fecha", align: "left", sortable: true, value: "fecha" },
      { text: "Comprobante", align: "left", sortable: true, value: "tipo" },
      { text: "N° Comprobante", align: "left", sortable: true, value: "nro_comprobante" },
      { text: "Importe imputado", align: "left", sortable: true, value: "total_imputado" },
    ],
    pagination: {
      page: 1,
      itemsPerPage: 5,
    },
  }),
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  watch: {
    show(val) {
      if (val) {
        this.loadList();
      }
    },
  },
  mounted() {},
  methods: {
    updatePagination(pagination) {
      this.pagination = pagination;
    },
  
    close() {
      this.list = [];
      this.$emit("closeDialog");
    },

    loadList() {
      this.loading = true;

      const param = {
        voucher_id: this.voucher_id,
      };

      vouchersService
        .get_balances(param)
        .then((response) => {
          
          this.list = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
