<template>
  <div>
    <Title :pages="[{ icon: 'circle', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <ModalInitialBalance
        :parent="parent"
        :modal_title="$t('Saldo inicial de') + ' ' + mainItem.name"
        :dialog="dialog_initial_balance"
        :item="mainItem"
        button_name="Saldo inicial"
        :formBtn="formBtn"
        :section="Constant.SECTION_SUPPLIERS"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        @closeModal="closeModalInitialBalance"
      />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-6">
                <div class="col-md-4 pt-0">
                  <v-radio-group
                    v-model="selected_status"
                    row
                    class="mt-0 mb-0"
                    @change="
                      $store.commit('setFilterSupplier', {
                        selected_status: selected_status,
                      })
                    "
                  >
                    <v-radio label="Habilitado" :value="true"></v-radio>
                    <v-radio label="No habilitado" :value="false"></v-radio>
                    <v-radio label="Todos" value="all"></v-radio>
                  </v-radio-group>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="supplier_type_selected"
                    autocomplete="off"
                    data-vv-as="supplier_type_selected"
                    name="supplier_type_selected"
                    item-text="name"
                    item-value="id"
                    :items="supplier_types"
                    label="Tipo"
                    class="pt-0 mt-0"
                    clearable
                    @change="
                      $store.commit('setFilterSupplier', {
                        supplier_type_selected: supplier_type_selected,
                      })
                    "
                    @clear="$store.commit('setFilterSupplier', { supplier_type_selected: null })"
                  ></v-combobox>
                </div>

                <div class="col-md-5 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details @input="updateSearch"></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
          :page="update_page"
          @update:page="updatePage"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.business_name }}</td>
              <td>{{ props.item.cuit }}</td>
              <td>{{ props.item.iva_condition != null ? props.item.iva_condition.name : "No especificada" }}</td>
              <td>{{ props.item.telephone }}</td>
              <td>{{ props.item.email }}</td>
              <td>{{ props.item.type != null ? props.item.type.name : "" }}</td>

              <td class="text-right">
                <span :class="props.item.balance == 0 ? '' : props.item.balance > 0 ? 'bg-round-green' : 'bg-round-red'">{{ formatUtils.formatPrice(props.item.balance) }}</span>
              </td>
              <td class="text-center">
                {{ props.item.enabled ? "SI" : "NO" }}
              </td>
              <td class="text-right" style="min-width: 200px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue-grey" class="mr-2" @click="goToBalances(props.item)"> description </v-icon>
                  </template>
                  <span>Ver Cta. Cte.</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="orange" class="mr-2" @click="goToComprobantesPendientes(props.item)"> payments </v-icon>
                  </template>
                  <span>Comprobantes pendientes</span>
                </v-tooltip>

                <!--<v-tooltip bottom v-if="props.item.initial_balance == null">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="purple" class="mr-2" @click="editInicialBalanceItem(props.item)"> request_quote </v-icon>
                  </template>
                  <span>Editar Saldo inicial</span>
                </v-tooltip>-->

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)"> remove_red_eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ModalInitialBalance from "@/components/ModalInitialBalance.vue";
import { suppliersService } from "../../../libs/ws/suppliersService";
export default {
  components: { Title, Modal, DialogDelete, ModalInitialBalance },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      Constant: Constant,
      dialog_initial_balance: false,
      formatUtils: formatUtils,
      parent: this,
      service: suppliersService,
      page_title: "Proveedores",
      modal_title: "Proveedor",
      selected_status: this.$store.state.supplier.filter.selected_status,
      supplier_type_selected: this.$store.state.supplier.filter.supplier_type_selected,
      search: this.$store.state.supplier.filter.search,
      mainItem: {
        id: "",
        name: "",
        cuit: "",
        iva_condition: null,
        telephone: "",
        email: "",
        business_name: "",
        external_reference: "",
        enabled: true,
        type: null,
      },
      defaultItem: null,
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Razón social",
          align: "left",
          sortable: true,
          value: "business_name",
        },
        {
          text: "CUIT",
          align: "left",
          sortable: true,
          value: "cuit",
        },
        {
          text: "Condición frente al IVA",
          align: "left",
          sortable: true,
          value: "iva_condition.name",
        },
        {
          text: "Telefono",
          align: "left",
          sortable: true,
          value: "telephone",
        },
        {
          text: "Email",
          align: "left",
          sortable: true,
          value: "email",
        },
        {
          text: "Tipo",
          align: "left",
          sortable: true,
          value: "type.name",
        },
        { text: "Saldo Acumulado", value: "balance", align: "center" },
        { text: "Habilitado", value: "enabled", align: "center" },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      dialog_delete: false,
      update_page: 1,
      supplier_types: [],
    };
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    // this.service.setSection(Constant.SECTION_SUPPLIERS);
    this.loadList(this.service);
    this.loadSupplierTypes();
  },
  methods: {
    updateSearch(value) {
      this.search = value;
      this.$store.commit("setFilterSupplier", { search: value });
    },
    updatePage(page) {
      this.$store.commit("setCurrentPageSupplier", page);
      this.update_page = page;
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .filter(param)
        .then((response) => {
          if (this.$store.state.supplier.pagination.page > 1) {
            this.update_page = this.$store.state.supplier.pagination.page;
          }

          this.list = response.result;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    goToComprobantesPendientes(item) {
      this.$router.push("/reports/supplier/pending-vouchers/" + item.id);
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    goToBalances(item) {
      this.$router.push(Constant.SECTION_SUPPLIERS_BALANCES + "/" + item.id);
    },
    editInicialBalanceItem(item) {
      this.errors.clear();
      this.editedIndex = this.list.indexOf(item);
      this.mainItem = Object.assign({}, item);
      this.dialog_initial_balance = true;
    },
    closeModalInitialBalance() {
      this.dialog_initial_balance = false;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    getFilterParams() {
      const param = {
        enabled: this.selected_status,
        type: this.supplier_type_selected != null ? this.supplier_type_selected.id : null,
      };
      return param;
    },
    loadSupplierTypes() {
      generalService.setSection(Constant.SECTION_SUPPLIER_TYPE);
      generalService
        .filter()
        .then((response) => {
          this.supplier_types = response.result;
        })
        .catch((e) => {
          console.log("supplier_types.filter error");
          console.log(e);
        });
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
