import { wsService } from "./wsService";
import sections from "@/constants/sections";

const section = sections.SECTION_VOUCHERS;

const IVA_N_A = 0;
const IVA_NG = 1; //NO GRAVADO intenro
const IVA_E = 2; //NO GRAVADO intenro
const IVA_0 = 3;
const IVA_2_5 = 9;
const IVA_5 = 8;
const IVA_10_5 = 4;
const IVA_21 = 5;
const IVA_27 = 6;

function NoIVAList() {
  return [IVA_NG, IVA_E, IVA_0, IVA_N_A];
}

function VoucherList() {
  return [901, 1, 6, 11, 51, 201, 206, 211];
}

function NoteCreditList() {
  return [3, 8, 13, 203, 208, 213, 903];
}

function NoteDebittList() {
  return [2, 7, 12, 202, 207, 212, 902];
}

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function getVoucherAsoc(emitter, voucher_number, pto_vta, cbte_tipo, receiver) {
  return wsService.make_post_request(section + "/get_voucher_asoc", { emitter: emitter, voucher_number: voucher_number, pto_vta: pto_vta, cbte_tipo: cbte_tipo, receiver: receiver });
}

function create(params) {
  return wsService.make_post_request(section + "/create", params);
}

function update(params) {
  return wsService.make_post_request(section + "/update", params);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function print(voucher_id) {
  return wsService.make_post_request(
    section + "/print/" + voucher_id,
    {},
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }
  );
}

function sendToAFIP(voucher_id) {
  return wsService.make_post_request(section + "/send_to_afip/" + voucher_id, {});
}

function getLastvoucherOf(emitter_id, reciver_id) {
  return wsService.make_post_request(section + "/get_last_voucher_of", { emitter: emitter_id, receiver: reciver_id });
}

function export_filter(params) {
  return wsService.make_post_request(section + "/export", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function export_libro_iva_filter(params) {
  return wsService.make_post_request(section + "/export_libro_iva", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function getPendingVouchers(params) {
  return wsService.make_post_request(section + "/get_pending_vouchers", params);
}

function getCancelIdForCbteTipo(cbte_tipo) {
  switch (cbte_tipo) {
    case 901:
      return 903; //FACTURA_X -> NOTA_CREDITO_X
    case 1:
      return 3; //FACTURA_A -> NOTA_CREDITO_A
    case 6:
      return 8; //FACTURA_B -> NOTA_DEBITO_B
    case 11:
      return 13; //FACTURA_C -> NOTA_DEBITO_C
    case 51:
      return 53; //FACTURA_M -> NOTA_DEBITO_M
    case 201:
      return 203; //FACTURA_CRED_ELEC_A -> NOTA_CREDITO_A
    case 206:
      return 208; //FACTURA_CRED_ELEC_B -> NOTA_CREDITO_B
    case 211:
      return 213; //FACTURA_CRED_ELEC_C -> NOTA_CREDITO_C
  }
}

function isElectronicVoucher(cbte_tipo) {
  if (cbte_tipo == null) return false;
  return [201, 206, 211, 203, 208, 213, 202, 207, 212].includes(cbte_tipo.Id);
}

function get(id) {
  return wsService.make_get_request(section + "/get/" + id);
}

function getShippingTypes() {
  return wsService.make_get_request(section + "/get_shipping_types");
}

function disassociate(voucher_id, voucher_asoc_id) {
  const params = { voucher: voucher_id, voucher_asoc: voucher_asoc_id };
  return wsService.make_post_request(section + "/disassociate", params);
}

function associate(voucher_id, voucher_asoc_id) {
  const params = { voucher: voucher_id, voucher_asoc: voucher_asoc_id };
  return wsService.make_post_request(section + "/associate", params);
}

function get_balances(params) {
  return wsService.make_post_request(section + "/get-balances", params);
}

export const vouchersService = {
  filter,
  getVoucherAsoc,
  create,
  update,
  remove,
  print,
  sendToAFIP,
  export_filter,
  export_libro_iva_filter,
  getLastvoucherOf,
  getPendingVouchers,
  getCancelIdForCbteTipo,
  NoIVAList,
  NoteCreditList,
  NoteDebittList,
  VoucherList,
  isElectronicVoucher,
  getShippingTypes,
  get,
  associate,
  disassociate,
  get_balances,
  IVA_0,
  IVA_10_5,
  IVA_21,
  IVA_27,
  IVA_2_5,
  IVA_5,
  IVA_E,
  IVA_NG,
};
