<template>
  <v-dialog v-bind:value="dialog" @click:outside="$emit('close')" v-on:input="$emit('close')" max-width="1200px">
    <v-card>
      <v-form v-on:submit.prevent="importar($event)">
        <v-card-title>
          <span class="headline">{{ modal_title }}</span>
        </v-card-title>

        <v-card-text style="min-height: 450px">
          <v-stepper v-model="step" class="elevation-0">
            <v-stepper-header style="background: #607d8b; color: white">
              <v-stepper-step :complete="step > 1" step="1"> <span class="text-white">Paso 1</span> </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2"> <span class="text-white">Paso 2</span> </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <div class="row justify-content-center">
                  <div class="col-md-5 pt-10">
                    <p class="fs-16">1) Descargue el archivo excel para carga masiva</p>

                    <v-btn color="primary" class="mb-4 text-center ml-4" small
                      ><a :href="getAPIUrl() + 'storage/excel_masivos/importacion_masiva_clientes.xlsx'" class="text-white">Descargar</a></v-btn
                    >

                    <p class="fs-16">2) Complete el excel con los datos de sus clientes</p>
                    <p class="fs-16">3) Importe el excel con los datos completos</p>

                    <v-file-input
                      class="mt-3"
                      accept=".xlsx"
                      label="Seleccionar excel"
                      v-model="excel"
                      hint="Solo admite archivos formato .xlsx (No admite formato .xls)"
                      persistent-hint
                    ></v-file-input>

                    <v-btn color="primary" class="mt-3 float-right" small @click="readExcel" :disabled="excel == null">Siguiente</v-btn>
                  </div>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <div style="border: solid 1px #999">
                  <v-data-table
                    :headers="headers"
                    :items="list_bulk"
                    :search="search"
                    :loading="loading"
                    :options="pagination"
                    loading-text="Cargando... Por favor espere"
                    @pagination="updatePagination"
                  >
                    <template v-slot:item="props">
                      <tr>
                        <td>{{ props.item.object.name }}</td>
                        <td>{{ props.item.object.business_name }}</td>
                        <td>{{ props.item.object.cuit }}</td>
                        <td>{{ props.item.object.address }}</td>
                        <td>{{ props.item.object.telephone }}</td>
                        <td>{{ props.item.object.invoiced_email }}</td>
                        <td>{{ props.item.object.external_reference }}</td>
                        <td style="min-width: 200px" v-if="!props.item.loading && props.item.status == 1 && props.item.msg == '' && !props.item.status_create">
                          <i class="fa fa-check text-green"></i>
                        </td>
                        <td style="min-width: 200px" v-if="!props.item.loading && props.item.status == 1 && props.item.msg != '' && !props.item.status_create">
                          <span class="text-red">{{ props.item.msg }}</span>
                        </td>
                        <td style="min-width: 200px" v-if="!props.item.loading && props.item.status == 1 && props.item.status_create">
                          <span class="text-green">Importado</span>
                        </td>

                        <td style="min-width: 200px" v-if="!props.item.loading && props.item.status == 0">
                          {{ "Pendiente de procesado" }}
                        </td>
                        <td style="min-width: 200px" v-if="props.item.loading">Procesando...</td>
                      </tr>
                    </template>
                    <template v-slot:no-results>
                      <v-alert :value="true" color="error" icon="warning">
                        {{ "Busqueda sin resultados - " + search }}
                      </v-alert>
                    </template>
                  </v-data-table>
                </div>
                <v-btn small class="text-white mt-3 mb-2" @click="step = step - 1" v-if="step != 1" color="red"> Atras </v-btn>

                <div class="pt-0 mt-3">
                  <p style="color: #1976d2; float: left; margin-right: 30px; font-size: 16px; margin-bottom: 5px">Clientes pendientes de procesar: {{ getPendienteProcesar() }}</p>
                  <p class="text-green" style="float: left; margin-right: 30px; font-size: 16px; margin-bottom: 5px">Clientes procesados validos: {{ getProcesadosValidos() }}</p>
                  <p style="color: red; margin-bottom: 0px; font-size: 16px; margin-bottom: 5px">Clientes procesados invalidos: {{ getProcesadosNoValidos() }}</p>
                </div>
                <div class="row mt-1" v-if="loading_create">
                  <div class="col-md-12">
                    <v-progress-linear v-model="percent" color="blue-grey" style="height: 25px !important">
                      <strong>{{ Math.ceil(percent) }}%</strong>
                    </v-progress-linear>
                  </div>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <!-- START FORM DIALOG -->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="$emit('close')">Cerrar</v-btn>
          <v-btn v-if="formBtn != ''" text color="green" type="submit" :loading="loading_create" :disabled="list_bulk.length == 0"> Importar </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import readXlsxFile from "read-excel-file";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
export default {
  props: {
    modal_title: String,
    dialog: Boolean,
    service: Object,
    mainItem: Object,
    viewdIndex: Number,
    formBtn: String,
    open: Function,
    close: Function,
    filter: Function,
    create: Function,
  },
  mixins: [ModalGenericMixin],
  components: {},
  data() {
    return {
      formatUtils: formatUtils,
      step: 1,
      object: null,
      list_bulk: [],
      money_accounts: [],
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Razon Social",
          align: "left",
          sortable: true,
          value: "business_name",
        },
        {
          text: "CUIT",
          align: "left",
          sortable: true,
          value: "cuit",
        },
        {
          text: "Direccion",
          align: "left",
          sortable: true,
          value: "address",
        },
        {
          text: "Telefono",
          align: "left",
          sortable: true,
          value: "telephone",
        },
        {
          text: "Email",
          align: "left",
          sortable: true,
          value: "invoiced_email",
        },
        {
          text: "Id. Externo",
          align: "left",
          sortable: true,
          value: "external_reference",
        },
        { text: "Resultado", value: "msg" },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 15,
      },
      search: "",
      loading: false,
      excel: null,
      envios_no_validos: 0,
      envios_creados: 0,
      money_account: null,
      loading_create: false,
    };
  },
  computed: {
    percent: {
      get() {
        return ((this.getProcesadosValidos() + this.getProcesadosNoValidos()) * 100) / this.list_bulk.length;
      },
      set(value) {
        return value;
      },
    },
  },
  watch: {
    dialog() {
      this.errors.clear();
      this.$validator.reset();
      this.excel = null;
      this.money_account = null;
      this.loading_create = false;
      this.list_bulk = [];
      this.step = 1;
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    getAPIUrl() {
      return process.env.VUE_APP_API_URL;
    },
    getPendienteProcesar() {
      if (this.list_bulk.length == 0) {
        return 0;
      }
      return this.list_bulk.filter((element) => element.status === 0).length;
    },
    getProcesadosValidos() {
      if (this.list_bulk.length == 0) {
        return 0;
      }
      return this.list_bulk.filter((element) => element.status === 1 && element.status_create).length;
    },
    getProcesadosNoValidos() {
      if (this.list_bulk.length == 0) {
        return 0;
      }
      return this.list_bulk.filter((element) => element.status === 1 && !element.status_create).length;
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_MONEY_ACCOUNTS);
      generalService
        .filter({ has_bank: true })
        .then((response) => {
          this.money_accounts = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("money_accounts.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    parseObject(element) {
      try {
        var object = Object.assign({}, this.mainItem);
        object.name = element[0];
        object.business_name = element[1];
        object.cuit = element[2];
        object.address = element[3];
        object.telephone = element[4];
        object.invoiced_email = element[5] != null ? element[5].toLowerCase() : null;
        object.external_reference = element[6] != null ? element[6].toString() : null;
      } catch (e) {
        console.log(element);
        console.log(e);
      }
      return object;
    },
    readExcel() {
      this.step++;
      this.list_bulk = [];
      this.envios_no_validos = 0;
      this.envios_creados = 0;
      try {
        readXlsxFile(this.excel, { sheet: 1 }).then((rows) => {
          rows.forEach((element, index) => {
            if (index >= 1) {
              var obj = {
                object: this.parseObject(element),
                msg: "",
                status: 0,
                status_create: false,
                loading: false,
              };

              this.list_bulk.push(obj);
            }
          });
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("Ocurrio un error al leer el archivo excel.", "Error");
      }
    },
    async importar() {
      var index = 0;
      this.loading_create = true;
      for (const element of this.list_bulk) {
        //sin mensaje entonces es valido para crear
        if (this.list_bulk[index].msg == "" && !this.list_bulk[index].status_create && this.list_bulk[index].status == 0) {
          this.list_bulk[index].loading = true;
          let result = await this.createItem(this.list_bulk[index].object);

          this.list_bulk[index].loading = false;
          this.list_bulk[index].status = 1;
          this.list_bulk[index].status_create = result.status;
          this.list_bulk[index].msg = this.parseErrorResultValidation(result);
          if (result.status) {
            this.envios_creados++;
          }
        }

        index++;
      }

      this.loading_create = false;

      if (this.list_bulk.length - this.envios_no_validos == this.envios_creados) {
        this.$toast.success("Se crearon " + this.envios_creados + " clientes correctamente.", "Ok");
        this.$emit("close");
        this.$emit("filter");
      } else {
        this.$toast.warning("Se crearon " + this.envios_creados + " clientes, pero " + (this.list_bulk.length - this.envios_no_validos) + " no se pudieron crear.", "Precaucion");
        //this.$emit("close");
        this.$emit("filter");
      }
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    async createItem(item) {
      if (item.amount < 0) {
        item.amount = item.amount * -1;
      }
      return this.service.create(item);
    },
  },
};
</script>
