const SECRET_PHRASE = "Ingen1@r*urriesti";

const SECTION_ROLES = "roles";
const SECTION_EMPLOYEES = "employees";

const SECTION_PAYMENT_METHODS = "payment_methods";

const SECTION_CLIENTS = "clients";
const SECTION_CLIENTS_BALANCES = "clients/balances";
const SECTION_SUPPLIERS = "suppliers";
const SECTION_SUPPLIER_TYPE = "supplier-type";

const SECTION_SUPPLIERS_BALANCES = "suppliers/balances";

const SECTION_PRODUCTS = "products";
const SECTION_PRODUCT_TYPE = "product-type";

const SECTION_CATEGORIES = "categories";
const SECTION_SUBCATEGORIES = "subcategories";
const SECTION_DELIVERY_POINT = "delivery_points";

const SECTION_REMITOS = "remitos";

const SECTION_MONO_CATEGORIES = "mono_categories";
const SECTION_USERS = "users";

const SECTION_STORAGE = "storage";
const SECTION_BANKS = "banks";

const SECTION_METRICS = "metrics";
const SECTION_COSTS_CENTERS = "costs_centers";
const SECTION_SUB_COSTS_CENTERS = "sub_costs_centers";
const SECTION_REPORT_COSTS_CENTERS = "reports/costs_centers";
const SECTION_REPORT_IVA_DIGITAL = "reports/iva-libro-digital";

const SECTION_WAREHOUSES = "warehouses";
const SECTION_STOCKS = "stocks";
const SECTION_HISTORIAL_STOCKS = "historial_stocks";

//Facturacion
const SECTION_LIST_DOC_TYPES = "billing/list_doc_types";
const SECTION_LIST_IVA_CONDITIONS_EMITTER = "billing/list_iva_conditions_emitter";
const SECTION_LIST_IVA_CONDITIONS = "billing/list_iva_conditions";
const SECTION_LIST_STATES = "billing/list_states";
const SECTION_CBTE_TIPOS = "billing/cbte_tipos";
const SECTION_EMITTERS = "emitters";
const SECTION_RECEIVERS = "receivers";

const SECTION_SALE_ORDER = "sale_order";

const SECTION_PURCHASE_ORDER = "purchase_order";


const SECTION_VOUCHERS = "vouchers";
const SECTION_PRICE_LIST = "price_lists";

const SECTION_MONEY_ACCOUNTS = "money_accounts";
const SECTION_SPENTS = "spents";
const SECTION_SPENT_PAYMENTS = "spent_payments";
const SECTION_BANKS_MOVEMENTS = "banks_movements";

const SECTION_CHECKS = "checks";
const SECTION_CHARGES = "charges";
const SECTION_INTEGRATIONS = "integrations";
const SECTION_SETTINGS = "settings";
const SECTION_SALESMAN = "salesman";

const SECTION_CURRENCY = "currency";
const SECTION_UNITS = "units";

const SECTION_REPORTS = "reports";
const SECTION_REPORTE_CTA_CTE_CLIENT = "reports/ctacte/client";
const SECTION_REPORTE_CTA_CTE_SUPPLIER = "reports/ctacte/supplier";
const SECTION_REPORTE_CLIENT_PENDING_CHARGE = "reports/client/pending-vouchers";
const SECTION_REPORTE_SUPPLIER_PENDING_SPENT = "reports/supplier/pending-vouchers";
const SECTION_REPORTE_SALES_BY_PRODUCT = "reports/sales";
const SECTION_REPORTE_PURCHASE = "reports/purchase";
const SECTION_REPORT_SALESMAN_COMMISSION = "reports/sales/salesman-commissions";
const SECTION_REPORT_RETENTIONS = "reports/retentions";
const SECTION_REPORT_PERCEPTIONS = "reports/perceptions";

//logistic
const SECTION_CARRIER = "logistic-carrier";
const SECTION_RATE = "logistic-rate";
const SECTION_ZONE = "logistic-zone";
const SECTION_SHIPPING = "logistic-shipping";

const SECTION_SEND_EMAIL = "send-email";

const SECTION_CLIENT_TYPE = "client-type";
const SECTION_CLIENT_ZONE = "client-zone";

export default {
  SECTION_CURRENCY: SECTION_CURRENCY,
  SECRET_PHRASE: SECRET_PHRASE,

  SECTION_CHECKS: SECTION_CHECKS,
  SECTION_ROLES: SECTION_ROLES,
  SECTION_EMPLOYEES: SECTION_EMPLOYEES,
  SECTION_BANKS: SECTION_BANKS,

  SECTION_CLIENTS: SECTION_CLIENTS,
  SECTION_CLIENTS_BALANCES: SECTION_CLIENTS_BALANCES,
  SECTION_DELIVERY_POINT: SECTION_DELIVERY_POINT,

  SECTION_REMITOS: SECTION_REMITOS,

  SECTION_SUPPLIERS: SECTION_SUPPLIERS,
  SECTION_SUPPLIERS_BALANCES: SECTION_SUPPLIERS_BALANCES,

  SECTION_UNITS: SECTION_UNITS,
  SECTION_PAYMENT_METHODS: SECTION_PAYMENT_METHODS,
  SECTION_PRODUCTS: SECTION_PRODUCTS,
  SECTION_PRODUCT_TYPE:SECTION_PRODUCT_TYPE,
  SECTION_CATEGORIES: SECTION_CATEGORIES,
  SECTION_SUBCATEGORIES: SECTION_SUBCATEGORIES,
  SECTION_MONO_CATEGORIES: SECTION_MONO_CATEGORIES,

  SECTION_STORAGE: SECTION_STORAGE,

  SECTION_METRICS: SECTION_METRICS,
  SECTION_PRICE_LIST: SECTION_PRICE_LIST,

  SECTION_EMITTERS: SECTION_EMITTERS,
  SECTION_RECEIVERS: SECTION_RECEIVERS,
  SECTION_LIST_DOC_TYPES: SECTION_LIST_DOC_TYPES,
  SECTION_LIST_IVA_CONDITIONS_EMITTER: SECTION_LIST_IVA_CONDITIONS_EMITTER,
  SECTION_LIST_IVA_CONDITIONS: SECTION_LIST_IVA_CONDITIONS,
  SECTION_LIST_STATES: SECTION_LIST_STATES,
  SECTION_CBTE_TIPOS: SECTION_CBTE_TIPOS,

  SECTION_SALE_ORDER:SECTION_SALE_ORDER,
  SECTION_PURCHASE_ORDER:SECTION_PURCHASE_ORDER,
  SECTION_VOUCHERS: SECTION_VOUCHERS,

  SECTION_MONEY_ACCOUNTS: SECTION_MONEY_ACCOUNTS,
  SECTION_SPENTS: SECTION_SPENTS,
  SECTION_SPENT_PAYMENTS: SECTION_SPENT_PAYMENTS,
  SECTION_BANKS_MOVEMENTS: SECTION_BANKS_MOVEMENTS,

  SECTION_CHARGES: SECTION_CHARGES,
  SECTION_USERS: SECTION_USERS,
  SECTION_INTEGRATIONS: SECTION_INTEGRATIONS,
  SECTION_SETTINGS: SECTION_SETTINGS,
  SECTION_REPORTS: SECTION_REPORTS,
  SECTION_REPORTE_CTA_CTE_CLIENT: SECTION_REPORTE_CTA_CTE_CLIENT,
  SECTION_REPORTE_CTA_CTE_SUPPLIER: SECTION_REPORTE_CTA_CTE_SUPPLIER,
  SECTION_REPORTE_CLIENT_PENDING_CHARGE:SECTION_REPORTE_CLIENT_PENDING_CHARGE,
  SECTION_REPORTE_SUPPLIER_PENDING_SPENT:SECTION_REPORTE_SUPPLIER_PENDING_SPENT,
  SECTION_REPORTE_SALES_BY_PRODUCT: SECTION_REPORTE_SALES_BY_PRODUCT,

  SECTION_WAREHOUSES: SECTION_WAREHOUSES,
  SECTION_STOCKS: SECTION_STOCKS,
  SECTION_HISTORIAL_STOCKS: SECTION_HISTORIAL_STOCKS,
  
  SECTION_REPORT_SALESMAN_COMMISSION: SECTION_REPORT_SALESMAN_COMMISSION,
  SECTION_REPORT_RETENTIONS: SECTION_REPORT_RETENTIONS,
  SECTION_REPORT_PERCEPTIONS: SECTION_REPORT_PERCEPTIONS,
  SECTION_SALESMAN: SECTION_SALESMAN,
  SECTION_COSTS_CENTERS: SECTION_COSTS_CENTERS,
  SECTION_SUB_COSTS_CENTERS: SECTION_SUB_COSTS_CENTERS,

  SECTION_REPORT_COSTS_CENTERS: SECTION_REPORT_COSTS_CENTERS,
  SECTION_REPORT_IVA_DIGITAL:SECTION_REPORT_IVA_DIGITAL,
  SECTION_REPORTE_PURCHASE:SECTION_REPORTE_PURCHASE,

  SECTION_CARRIER: SECTION_CARRIER,
  SECTION_RATE: SECTION_RATE,
  SECTION_ZONE: SECTION_ZONE,
  SECTION_SHIPPING: SECTION_SHIPPING,
  SECTION_SEND_EMAIL:SECTION_SEND_EMAIL,

  SECTION_CLIENT_TYPE:SECTION_CLIENT_TYPE,
  SECTION_CLIENT_ZONE:SECTION_CLIENT_ZONE,

  SECTION_SUPPLIER_TYPE:SECTION_SUPPLIER_TYPE
};
