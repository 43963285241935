<template>
  <v-dialog v-bind:value="show" @click:outside="$emit('close', false)" max-width="600px">
    <v-card>
      <v-form v-on:submit.prevent="aceptar">
        <v-card-title>
          <span class="headline">{{ modal_title }}</span>
        </v-card-title>

        <v-card-text>
          <div class="row">
            <div class="col-md-6 mt-4" v-if="show_date">
              <DatePickerEsp
                :initToday="true"
                :label="label_date"
                :date="mainItem.date"
                :isRequired="true"
                name="fecha"
                @setDate="
                  (date) => {
                    mainItem.date = date;
                  }
                "
              />
            </div>
          </div>
          <div class="row" v-if="show_money_account">
            <div class="col-md-6">
              <v-combobox
                v-model="mainItem.money_account"
                data-vv-as="Cuenta monetaria"
                name="money_account"
                item-text="name"
                item-value="id"
                :items="money_accounts"
                label="Cuenta monetaria*"
                :loading="loading_banks"
                autocomplete="off"
                v-validate="'required|combobox_required'"
              ></v-combobox>
              <span class="red--text">{{ errors.first("money_account") }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="subtitle-2">Cheques</label>
              <v-divider></v-divider>
              <div v-for="check of cheques" :key="check.id">
                <v-chip small class="mt-1">{{ check.bank.name }} - Nro:{{ check.number }} - {{ formatUtils.formatPrice(check.amount) }} </v-chip>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="$emit('close', false)">Cerrar</v-btn>
          <v-btn color="success" text type="submit" :loading="loading">Aceptar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { formatUtils } from "@/libs/utils/formatUtils";
import { checkService } from "@/libs/ws/checkService";

export default {
  components: { DatePickerEsp },
  props: {
    modal_title: String,
    show: Boolean,
    action: String,
    close: Function,
    refresh: Function,
    cheques: Array,
  },
  data() {
    return {
      formatUtils: formatUtils,
      loading: false,
      mainItem: {
        action: null,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        money_account: null,
      },
      label_date: "Fecha",
      show_date: true,
      show_money_account: true,
      money_accounts: [],
      loading_banks: false,
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.$validator.reset();
        this.mainItem.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
        this.mainItem.money_account = null;

        switch (this.action) {
          case "acreditar":
            this.mainItem.action = "acredit";
            this.label_date = "Fecha de acreditación";
            this.show_date = true;
            this.show_money_account = false;
            return;
          case "negotiate":
            this.mainItem.action = "negotiate";
            this.label_date = "Fecha de negociación";
            this.show_date = true;
            this.show_money_account = false;
            return;
          case "cobrar_por_ventanilla":
            this.mainItem.action = "charge_window";
            this.label_date = "Fecha de cobro";
            this.show_date = true;
            this.show_money_account = true;
            this.loadMonetaryAccounts(false);
            return;
          case "debitar":
            this.mainItem.action = "debit";
            this.label_date = "Fecha de debito";
            this.show_date = true;
            this.show_money_account = true;
            this.loadMonetaryAccounts(true);
            return;
          case "depositar":
            this.mainItem.action = "deposit";
            this.label_date = "Fecha de deposito";
            this.show_date = true;
            this.show_money_account = true;
            this.loadMonetaryAccounts(true);
            return;
          case "rechazar":
            this.mainItem.action = "reject";
            this.label_date = "Fecha de rechazo";
            this.show_date = true;
            this.show_money_account = false;
            return;
          case "anular":
            this.mainItem.action = "anular";
            this.label_date = "Fecha de anulación";
            this.show_date = true;
            this.show_money_account = false;
            return;
          default:
            return;
        }
      }
    },
  },
  mounted() {},
  methods: {
    aceptar() {
      this.loading = true;
      var param = {
        action: this.mainItem.action,
        money_account: this.mainItem.money_account,
        checks_ids: this.cheques.map((x) => x.id),
      };

      switch (this.action) {
        case "negotiate":
          param.negotiated_date = this.mainItem.date;
          break;
        case "acreditar":
          param.accredit_date = this.mainItem.date;
          break;
        case "cobrar_por_ventanilla":
          param.charge_window_date = this.mainItem.date;
          break;
        case "debitar":
          param.debit_date = this.mainItem.date;
          break;
        case "depositar":
          param.deposit_date = this.mainItem.date;
          break;
        case "rechazar":
          param.reject_date = this.mainItem.date;
          break;
        case "anular":
          param.anulado_date = this.mainItem.date;
          break;
        default:
          break;
      }

      checkService
        .changeState(param)
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.$emit("close", true);
            this.$emit("refresh");
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("checkService.changeState error");
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadMonetaryAccounts(has_bank) {
      var param = { has_bank: has_bank };
      generalService.setSection(Constant.SECTION_MONEY_ACCOUNTS);
      this.loading_banks = true;
      generalService
        .filter(param)
        .then((response) => {
          this.money_accounts = response.result;
        })
        .catch((e) => {
          console.log("money_accounts.filter error");
          console.log(e);
        })
        .finally(() => {
          this.loading_banks = false;
        });
    },
  },
};
</script>
