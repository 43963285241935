<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name
          }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-9">
                    <v-text-field autocomplete="off" data-vv-as="cuit" label="CUIT (solo numeros)" name="cuit"
                      v-validate="{
                        required: false,
                        regex: /\b(20|23|24|27|30|33|34)[0-9]{8}[0-9]/,
                      }" v-model="item.cuit" :disabled="isViewModal()"></v-text-field>
                    <span class="red--text">{{ errors.first("cuit") }}</span>
                  </div>

                  <div class="col-3">
                    <v-btn color="blue-grey" class="w-100 mt-3 text-white" @click="query_padron()"
                      :loading="loading_search_afip"> <font-awesome-icon icon="search" class="mr-3" /> AFIP </v-btn>
                  </div>
                </div>

                <v-text-field data-vv-as="name" label="Nombre*" name="name" v-model="item.name" v-validate="'required'"
                  :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>

                <v-combobox
                  label="Condición frente al IVA*"
                  v-model="item.iva_condition"
                  data-vv-as="Condición frente al IVA"
                  name="iva_condition"
                  :items="iva_conditions"
                  item-text="name"
                  item-value="id"
                  autocomplete="off"
                  v-validate="'required|combobox_required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("iva_condition") }}</span>

                <v-text-field data-vv-as="Razon Social" label="Razón social*" name="business_name"
                  v-model="item.business_name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("business_name") }}</span>

                <v-text-field data-vv-as="Email" label="Email" name="email" v-model="item.email"
                  :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("email") }}</span>

                <v-text-field data-vv-as="Telefono" label="Telefono" name="telephone" v-model="item.telephone"
                  :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("telefono") }}</span>

                <v-text-field data-vv-as="Direccion" label="Dirección" name="address" v-model="item.address"
                  :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("address") }}</span>

                <v-text-field data-vv-as="Identificador externo" label="Identificador externo" name="external_reference"
                  v-model="item.external_reference" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("external_reference") }}</span>

                <v-combobox v-model="item.type" autocomplete="off" data-vv-as="tipo" name="type" item-text="name"
                  item-value="id" :items="supplier_types" label="Tipo" clearable :disabled="isViewModal()"></v-combobox>
                <span class="red--text">{{ errors.first("type") }}</span>

                <v-text-field class="mb-4" type="number"
                  hint="Ingresa la cantidad de dias que deben pasar para considerar el pago de una factura atrasado"
                  persistent-hint data-vv-as="Pago atrasado" label="Pago atrasado" name="invoice_expiration"
                  v-model="item.invoice_expiration" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("invoice_expiration") }}</span>

                <v-checkbox label="Habilitado" :disabled="isViewModal()" v-model="item.enabled"></v-checkbox>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import Constant from "@/constants/sections";
import { billingGeneralService } from "@/libs/ws/billingGeneralService";
import { receiversService } from "@/libs/ws/receiversService";
import { generalService } from "@/libs/ws/generalService";

export default {
  mixins: [ModalGenericMixin],
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      iva_conditions: [],
      loading_search_afip: false,
      supplier_types: []
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadIVAConditions();
    this.loadSupplierTypes();
  },
  methods: {
    loadIVAConditions() {
      billingGeneralService.list_iva_conditions_emitter().then((response) => {
        this.iva_conditions = response.result;
      });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    loadSupplierTypes() {
      generalService.setSection(Constant.SECTION_SUPPLIER_TYPE);
      generalService
        .filter()
        .then((response) => {
          this.supplier_types = response.result;
        })
        .catch((e) => {
          console.log("supplier_types.filter error");
          console.log(e);
        });
    },
    query_padron() {
      if (this.item.cuit != null && this.item.cuit != "") {
        this.loading_search_afip = true;
        receiversService
          .query_padron({
            tax_id: this.item.cuit,
            doc_type: 80, //CUIT
          })
          .then((response) => {
            console.log(response);
            if (response.status == true) {
              this.item.business_name = response.result.name;
              this.item.name = response.result.name;
              this.item.address = response.result.address;
              this.item.location = response.result.location;
              this.item.provincia = response.result.provincia;
              this.item.postal_code = response.result.postal_code;

              //si la respuesta tiene iva_condition y existe la propiedad busco el id en el array de iva_conditions
              if (response.result.iva_condition != undefined && response.result.iva_condition != null) {
                this.item.iva_condition = this.iva_conditions.find((x) => x.id == response.result.iva_condition);
              }
            } else {
              this.$toast.error(response.msg, "Error");
            }
          })
          .catch((response) => {
            console.log(response);
            this.$toast.error(response.msg, "Error");
          })
          .finally(() => {
            this.loading_search_afip = false;
          });
      }
    },
    createItem(e) {
      if (this.loading) return;

      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();
          //Se crea data para reemplazar criteria_price co nel id
          const data = {
            id: this.item.id,
            name: this.item.name,
            cuit: this.item.cuit,
            iva_condition: this.item.iva_condition != null ? this.item.iva_condition.id : null,
            telephone: this.item.telephone,
            email: this.item.email,
            business_name: this.item.business_name,
            external_reference: this.item.external_reference,
            invoice_expiration: this.item.invoice_expiration,
            enabled: this.item.enabled,
            type: this.item.type
          };

          this.loading = true;
          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
  },
};
</script>
